.atomic-records {
    justify-content: center;
    margin-top: 4rem;
}

.atomic-records-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    width: 100dvw;
    margin-bottom: 50dvh; 
}

.changed-record {
    display: inline-flex;
    justify-content: left;
    width: 20rem;
    font-size: 1rem;
    margin: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 1rem;
    background-color: #9CD9EE; /* #84B7C9; */
    line-height: 1.7rem;
}

.textual {
    line-height: 1rem;
    font-size: 0.8rem;
}

.changed-record-small {
    font-size: 1rem;
    margin-top: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #9CD9EE; /* #84B7C9; */
    width: 96dvw;
    max-width: calc(18rem + 10dvw);
    border-radius: 0.3rem;
    box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.6);
}
.atomic-record-small-hidden {
    font-size: 1rem;
    margin-top: 1.5rem;
    padding-top: 0.7rem;
    padding-bottom: 0.2rem;
    background-color: #84B7C9;
    width: 70dvw;
    max-width: 14rem;
    padding-left: 5dvw;
    padding-right: 5dvw;
}
.date-hidden {
    margin-top: 0rem;
    margin-bottom: 0.5rem;
}
.debit-credit-hidden {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.amount-hidden {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
}

.descritopn-hidden {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
}

.changed-record-content {
    padding: 0rem;
    margin-bottom: 1rem;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    line-height: 1.7rem;
    text-align: left;
}

.new-atomic-record {
    position: fixed;
    cursor: move;
    top: 4rem;
    left: 50%;
    border: 0.2rem solid #84B7C9;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    z-index: 500;
}

.new-atomic-record-small {
    border: 1dvw solid #84B7C9;
    font-size: 1rem;
    margin-top: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #9CD9EE; /* #84B7C9; */
    width: 94dvw;
    margin-left: 2dvw;
    border-radius: 0.3rem;
    box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.6);
}

.new-atomic-record-small-hidden {
    border: 1dvw solid #84B7C9;
    font-size: 1rem;
    margin-top: 1.5rem;
    padding-top: 0.7rem;
    padding-bottom: 0.2rem;
    background-color: #84B7C9;
    width: 68dvw;
    margin-left: 10dvw;
    padding-left: 5dvw;
    padding-right: 5dvw;
}

.delete-warning {
    position: fixed;
    cursor: move;
    top: 50%;
    left: 50%;
    background-color: #F95906;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    max-height: 100dvh;
    font-size: 1rem;
    border-radius: 1rem;
    color: #ffffff;
    padding: 0px;
    width: 17rem;
}

.delete-warning-small {
    position: fixed;
    top: 50dvh;
    left: 5dvw;
    background-color: #F95906;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    font-size: 1rem;
    border-radius: 0.7rem;
    color: #ffffff;
    padding: 0px;
    width: 90dvw;
}

.ca {
    text-align: center;
}

.border-above {
    border-top: 1px dashed #626262;
}

.cant-do-warning {
    position: fixed;
    cursor: move;
    top: 50%;
    left: 50%;
    background-color: #FD7901;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    max-height: 100vh;
    font-size: 1rem;
    border-radius: 1rem;
    color: #ffffff;
    padding: 0px;
    width: 18.5rem;
}

.cant-do-warning-small {
    position: fixed;
    width: 90dvw;
    background-color: #FD7901;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    top: 50vh;
    left: 5dvw;
    font-size: 1rem;
    border-radius: 0.7rem;
    color: #ffffff;
}

p.warning {
    margin: 0.9rem;
}

p.warning-small {
    margin: 0.85rem;
}

button.warning-ok {
    font-size: 1rem;
    margin-bottom: 0.9rem;
    background-color: #FD7901;
    color: #ffffff;
    width: 4.5rem;
}

button.warning-ok-small {
    font-size: 1rem;
    margin-bottom: 0.35rem;
    background-color: #FD7901;
    color: #ffffff;
    width: 3rem;
}

button.warning-delete-ok {
    font-size: 1rem;
    margin-bottom: 0.9rem;
    background-color: #F95906;
    color: #ffffff;
    width: 4.5rem;
}

button.warning-delete-ok:hover {
    background-color: red;
    color: #ffffff;
}

button.warning-delete-ok-small {
    font-size: 1rem;
    margin-bottom: 0.35rem;
    background-color: red; /*#F95906;*/
    color: #ffffff;
    width: 3rem;
}

button.warning-delete-cancel {
    font-size: 1rem;
    margin-bottom: 0.9rem;
    width: 4.5rem;
}

button.warning-delete-cancel-small {
    font-size: 1rem;
    margin-bottom: 0.7rem;
    width: 6rem;
    margin-left: 1rem;
}

.atomic-record.dragging {
    z-index: 4000;
    cursor: grab;
    position: relative;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    transform: translate(0px, -0.4rem);
}

.new-atomic-record.dragging {
    z-index: 4000;
    position: fixed;
    cursor: grabbing;
}

.cant-do-warning.dragging {
    z-index: 4000;
    cursor: grabbing;
}

.delete-warning.dragging {
    z-index: 4000;
    cursor: grabbing;
}

h4 {
    font-size: 1rem;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0.5rem;
}

.gray-out {
    color: #626262;
}

div.filter-container {
    margin-right: 4.0rem;
    width: 100%;
    display: inline-flex;
}

div.filter-container-small {
    font-size: 1.8rem;
    font-weight: 400;
    margin-top: 1rem;
}



.tooltip-cell {
    position: relative;
    cursor: pointer;
}
.tooltip-text {
    visibility: hidden;
    min-width: 15rem;
    background-color: #ffffff;
    color: #000;
    text-align: center;
    border-radius: 0.5rem;
    padding: 0.25rem;
    position: absolute;
    z-index: 1;
    top: 1.5rem;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.6);
}
.tooltip-debit-old {
    left: 3.5rem;
    top: 2.6rem;
}
.tooltip-debit-new {
    left: -0.4rem;
    top: 2.6rem;
}
.tooltip-debit-small {
    left: 0.5rem;
    top: 2.5rem;
}
.tooltip-debit-small-hidden {
    left: 1.9rem;
    top: 2.5rem;
}
.tooltip-credit-old {
    left: 3.5rem;
    top: 2.6rem;
}
.tooltip-credit-new {
    left: -0.4rem;
    top: 2.6rem;
}
.tooltip-credit-small {
    left: 0.5rem;
    top: 2.5rem;
}
.tooltip-credit-small-hidden {
    left: -1.5rem;
    top: 2.5rem;
}
.tooltip-asset-account-old {
    left: 3.5rem;
    top: 2.6rem;
}
.tooltip-asset-account-new {
    left: -0.4rem;
    top: 2.6rem;
}
.tooltip-asset-account-small {
    left: 0.5rem;
    top: 2.5rem;
}
.tooltip-cell:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.atomic-record-button-container {
    margin-top: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.atomic-record-button {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    margin-right: 0.15rem;
    font-size: 1rem;
}

.changed-record-buttons {
    padding: 0.25rem 0.4rem 0.25rem 0.0rem;
    margin-top: 1rem;
    display: inline-flex;
    position: fixed;
    background-color: #ffffff;
    top: 1rem;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0.2rem solid #84B7C9;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    max-height: 100vh;
    z-index: 1000;
    justify-content: center;
}

.description.small {
    width: 100%;
}

.note.small {
    width: 100%;
}



.small-right {
    display: inline-box;
    text-align: right;
    flex-shrink: 0;
    line-height: 1.7rem;
    white-space: nowrap;
}

.small-right-div {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 1.7rem;
}

.small-right-div-date {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    line-height: 1.7rem;
}

.small-left {
    display: inline-block;
    text-align: left;
    flex-shrink: 0;
    line-height: 1.7rem;
}

.small-left-div {
    text-align: left;
    display: flex;
    justify-content: flex-start;
}

.span-two-columns {
    grid-column: span 2;
}

.span-three-columns {
    grid-column: span 3;
}

.assetAccount {
    margin-left: 0.3rem;
}

@media screen and (max-device-width: 768px) {
    .App {
        min-width: none;
    }
    h2.no-authorization {
      font-size: 1.2rem;
    }
    button {
      border-radius: 0.6rem; 
      padding: 0.2rem;
    }
    img.exitImg {
      height: 2.6rem;
      width: 2.6rem;
    }
    button.exit {
      height: 2.6rem;
      width: 2.6rem;
    }
}
  
@media screen and (min-device-width: 769px) {
    .App {
      min-width: 34rem;
    }
    h2.no-authorization {
      font-size: 1.2rem;
    }
    button {
      border-radius: 0.4rem;
    }
    img.exitImg {
      height: 2rem;
      width: 2rem;
    }
    button.exit {
      height: 2rem;
      width: 2rem;
    }
}
