.atomic-records {
    justify-content: center;
    margin-top: 4rem;
}

.atomic-records-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    width: 100dvw;
    margin-bottom: 50dvh; 
}

.swipedBack2 .atomic-records-small {
    transition: transform 0.15s ease;
    transform: translateX(100%);
}

.swipedBack3 .atomic-records-small {
    transition: transform 0s ease;
    transform: translateX(-100%);
}

.swipedBack4 .atomic-records-small {
    transition: transform 0.15s ease;
    transform: translateX(0%);
}

.swipedForth2 .atomic-records-small {
    transition: transform 0.15s ease;
    transform: translateX(-100%);
}

.swipedForth3 .atomic-records-small {
    transition: transform 0s ease;
    transform: translateX(100%);
}

.swipedForth4 .atomic-records-small {
    transition: transform 0.15s ease;
    transform: translateX(0%);
}

.arrow {
    font-size: 1.3rem;
}

.atomic-record {
    display: inline-flex;
    justify-content: left;
    width: 19rem;
    font-size: 1rem;
    margin: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 1rem;
    text-align: left;
    background-color: #9CD9EE; /* #84B7C9; */
    line-height: 1.5rem;
}

.atomic-record-small {
    font-size: 1rem;
    margin-top: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #9CD9EE; /* #84B7C9; */
    width: 96dvw;
    max-width: calc(18rem + 10dvw);
    border-radius: 0.3rem;
    box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.6);
}
.swipe-div {
    min-height: 95dvh;
    overflow: hidden;
    position: relative;
}
.atomic-record-small-hidden {
    font-size: 1rem;
    margin-top: 1.5rem;
    padding-top: 0.7rem;
    padding-bottom: 0.2rem;
    background-color: #84B7C9;
    width: 70dvw;
    max-width: 14rem;
    padding-left: 5dvw;
    padding-right: 5dvw;
}
.date-hidden {
    margin-top: 0rem;
    margin-bottom: 0.5rem;
}
.debit-credit-hidden {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.amount-hidden {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
}

.descritopn-hidden {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
}

.toggles {
    height: 2rem;
    width: 2rem;
}

.atomic-record-small form {
    padding-left: 5dvw;
    padding-right: 5dvw;
    width: 86dvw;
    max-width: 18rem;
    display: grid;
    align-items: center;
    grid-template-columns: auto minmax(0, 1fr);
    overflow: hidden;
    line-height: 1.7rem;
}

.new-atomic-record {
    position: fixed;
    cursor: move;
    top: 4rem;
    left: 50%;
    border: 0.2rem solid #84B7C9;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    z-index: 500;
}

.new-atomic-record-small {
    border: 1dvw solid #84B7C9;
    font-size: 1rem;
    margin-top: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #9CD9EE; /* #84B7C9; */
    width: 94dvw;
    margin-left: 2dvw;
    border-radius: 0.3rem;
    box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.6);
}

.new-atomic-record-small-hidden {
    border: 1dvw solid #84B7C9;
    font-size: 1rem;
    margin-top: 1.5rem;
    padding-top: 0.7rem;
    padding-bottom: 0.2rem;
    background-color: #84B7C9;
    width: 68dvw;
    margin-left: 10dvw;
    padding-left: 5dvw;
    padding-right: 5dvw;
}

.delete-warning {
    position: fixed;
    cursor: move;
    top: 50%;
    left: 50%;
    background-color: #F95906;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    max-height: 100dvh;
    font-size: 1rem;
    border-radius: 1rem;
    color: #ffffff;
    padding: 0px;
    width: 17rem;
}

.delete-warning-small {
    position: fixed;
    top: 50dvh;
    left: 5dvw;
    background-color: #F95906;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    font-size: 1rem;
    border-radius: 0.7rem;
    color: #ffffff;
    padding: 0px;
    width: 90dvw;
}

.cant-do-warning {
    position: fixed;
    cursor: move;
    top: 50%;
    left: 50%;
    background-color: #FD7901;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    max-height: 100vh;
    font-size: 1rem;
    border-radius: 1rem;
    color: #ffffff;
    padding: 0px;
    width: 18.5rem;
}

.cant-do-warning-small {
    position: fixed;
    width: 90dvw;
    background-color: #FD7901;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    top: 50vh;
    left: 5dvw;
    font-size: 1rem;
    border-radius: 0.7rem;
    color: #ffffff;
}

p.warning {
    margin: 0.9rem;
}

p.warning-small {
    margin: 0.85rem;
}

button.warning-ok {
    font-size: 1rem;
    margin-bottom: 0.9rem;
    background-color: #FD7901;
    color: #ffffff;
    width: 4.5rem;
}

button.warning-ok-small {
    font-size: 1rem;
    margin-bottom: 0.35rem;
    background-color: #FD7901;
    color: #ffffff;
    width: 3rem;
}

button.warning-delete-ok {
    font-size: 1rem;
    margin-bottom: 0.9rem;
    background-color: #F95906;
    color: #ffffff;
    width: 4.5rem;
}

button.warning-delete-ok:hover {
    background-color: red;
    color: #ffffff;
}

button.warning-delete-ok-small {
    font-size: 1rem;
    margin-bottom: 0.35rem;
    background-color: red; /*#F95906;*/
    color: #ffffff;
    width: 3rem;
}

button.warning-delete-cancel {
    font-size: 1rem;
    margin-bottom: 0.9rem;
    width: 4.5rem;
}

button.warning-delete-cancel-small {
    font-size: 1rem;
    margin-bottom: 0.7rem;
    width: 6rem;
    margin-left: 1rem;
}

.atomic-record.dragging {
    z-index: 4000;
    cursor: grab;
    position: relative;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    transform: translate(0px, -0.4rem);
}

.new-atomic-record.dragging {
    z-index: 4000;
    position: fixed;
    cursor: grabbing;
}

.cant-do-warning.dragging {
    z-index: 4000;
    cursor: grabbing;
}

.delete-warning.dragging {
    z-index: 4000;
    cursor: grabbing;
}

h4.new-record-title {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 1.0rem;
}

div.filter-container {
    margin-right: 4.0rem;
    width: 100%;
    display: inline-flex;
}

div.filter-container-small {
    font-size: 1.8rem;
    font-weight: 400;
    margin-top: 1rem;
}

.filter-label {
    padding-top: 0.3rem;
    font-size: 1.3rem;
    height: 1.5rem;
    background-color: #ffffff;
}
input.filter {
    padding-top: 0.1rem;
    font-size: 1.3rem;
    height: 1.9rem;
    background-color: #ffffff;
    border: 0;
    vertical-align: top;
}

.tooltip-cell {
    position: relative;
    cursor: pointer;
}
.tooltip-text {
    visibility: hidden;
    min-width: 15rem;
    background-color: #ffffff;
    color: #000;
    text-align: center;
    border-radius: 0.5rem;
    padding: 0.25rem;
    position: absolute;
    z-index: 1;
    top: 1.5rem;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.6);
}
.tooltip-debit {
    left: 6rem;
}
.tooltip-debit-small {
    left: 0.5rem;
    top: 2.5rem;
}
.tooltip-debit-small-hidden {
    left: 1.9rem;
    top: 2.5rem;
}
.tooltip-credit {
    left: 2.25rem;
}
.tooltip-credit-small {
    left: 0.5rem;
    top: 2.5rem;
}
.tooltip-credit-small-hidden {
    left: -1.5rem;
    top: 2.5rem;
}
.tooltip-asset-account {
    left: -4.5rem;
}
.tooltip-asset-account-small {
    left: 0.5rem;
    top: 2.5rem;
}
.tooltip-cell:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.doc-input {
    width: 2rem;
    margin-right: 0.1rem;
    border-radius: 0.3rem;
    line-height: 1.2rem;
    border-top: 0.1rem solid rgba(255, 255, 255, 1);
    border-bottom: 0.1rem solid rgba(108, 108, 108, 1);
    border-right: 0.1rem solid rgba(108, 108, 108, 1);
    border-left: 0.1rem solid rgba(255, 255, 255, 1);
}
.doc-input:focus {
    border-bottom: 0.1rem solid rgba(255, 255, 255, 1);
    border-top: 0.1rem solid rgba(108, 108, 108, 1);
    border-left: 0.1rem solid rgba(108, 108, 108, 1);
    border-right: 0.1rem solid rgba(255, 255, 255, 1);
  }

.atomic-record-button-container {
    margin-top: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.atomic-record-button {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    margin-right: 0.15rem;
    font-size: 1rem;
}
  
input {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, Arial, Helvetica, sans-serif;;
    height: 1.2rem;
    background-color: #9CD9EE;
    border: 0;
}
input.small {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, Arial, Helvetica, sans-serif;;
    height: 1.3rem;
    background-color: #9CD9EE;
    border: 0;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
}
input.small.debit, input.small.credit, input.small.currency, input.small.schedule {
    margin-right: -0.2rem;
}



textarea.regular {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, Arial, Helvetica, sans-serif;;
    background-color: #9CD9EE;
    border: 0;
    width: 100%;
}
textarea.small {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, Arial, Helvetica, sans-serif;;
    background-color: #9CD9EE;
    border: 0;
    width: 100%;
}

label {
    font-weight: 600;
}
input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
}
input[type="date"] {
    max-width: 9rem;
    text-align: right;
}
input.id {
    width: 4.25rem;
}
input.currency {
    width: 2.1rem;
}
input.currency.small {
    width: 2.1rem;
    margin-left: 0.3rem;
}
input.day {
    width: 2.15rem;
    text-align: right;
}
input.fx_day {
    width: 2.15rem;
    text-align: right;
}
input.day_start {
    width: 2.15rem;
    text-align: right;
}
input.month {
    width: 2.15rem;
    text-align: right;
}
input.fx_month {
    width: 2.15rem;
    text-align: right;
}
input.month_start {
    width: 2.15rem;
    text-align: right;
}
input.year {
    width: 3.35rem;
    text-align: right;
}
input.fx_year {
    width: 3.35rem;
    text-align: right;
}
input.year_start {
    width: 3.35rem;
    text-align: right;
}
input.amount {
    width: 4.25rem;
    text-align: right;
}
input.fx {
    width: 4.25rem;
    text-align: right;
}
input.debit {
    width: 2.5rem;
}
input.credit {
    width: 2.5rem;
}
input.schedule {
    width: 2.2rem;
    text-align: right;
}
input.asset-account {
    width: 2.5rem;
}
input.documents {
    width: 2.5rem;
}

.atomic-buttons {
    padding: 0.25rem;
    margin-top: 1rem;
    display: inline-flex;
    position: fixed;
    background-color: #ffffff;
    top: 1rem;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0.2rem solid #84B7C9;
    box-shadow: 0 0.5rem 0.9rem rgba(0, 0, 0, 0.6);
    max-height: 100vh;
    z-index: 1000;
    justify-content: center;
}

.switch-non-active, .switch-active {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 1.7rem;
}
.switch-non-active {
    opacity: 0.5;
}

.description.small {
    width: 100%;
}

.note.small {
    width: 100%;
}



.small-right {
    display: inline-box;
    text-align: right;
    flex-shrink: 0;
    line-height: 1.7rem;
    white-space: nowrap;
}

.small-right-div {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 1.7rem;
}

.small-right-div-date {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    line-height: 1.7rem;
}

.small-left {
    display: inline-block;
    text-align: left;
    flex-shrink: 0;
    line-height: 1.7rem;
}

.small-left-div {
    text-align: left;
    display: flex;
    justify-content: flex-start;
}

.span-two-columns {
    grid-column: span 2;
}

.assetAccount {
    margin-left: 0.3rem;
}

@media screen and (max-device-width: 768px) {
    .App {
        min-width: none;
    }
    h2.no-authorization {
      font-size: 1.2rem;
    }
    button {
      border-radius: 0.6rem; 
      padding: 0.2rem;
    }
    img.exitImg {
      height: 2.6rem;
      width: 2.6rem;
    }
    button.exit {
      height: 2.6rem;
      width: 2.6rem;
    }
  }
  
  @media screen and (min-device-width: 769px) {
    .App {
      min-width: 34rem;
    }
    h2.no-authorization {
      font-size: 1.2rem;
    }
    button {
      border-radius: 0.4rem;
    }
    img.exitImg {
      height: 2rem;
      width: 2rem;
    }
    button.exit {
      height: 2rem;
      width: 2rem;
    }
  }