.tabs-container {
    display: grid;
    place-content: center;
}

.upper-tabs {
    margin-top: 1rem;
}

.files-panel-outer-container {
    text-align: left;
    margin-left: 1rem;
}

.files-panel-container {
    overflow-x: auto;
    white-space: nowrap;
}

.files-panel {
    display: flex;
    padding-bottom: 0.7rem;
}

.file-box {
    text-align: center;
    background-color: #9CD9EE;
    padding: 0rem 1rem 0.8rem 1rem;
    border-radius: 1.7rem;
    margin-right: 0.8rem;
}

h4.file-name {
    margin-top: 1rem;
    margin-bottom: 0.3rem;
}

p.file-size {
    margin-top: 0.3rem;
}

td button {
  font-size: 0.8rem;
  padding: 0.15rem 0.4rem 0.15rem 0.4rem;
  border-radius: 0.6rem;
}

.tab {
  flex: 1;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  color: black;
  font-weight: 500;
  background-color: #9CD9EE;
  cursor: pointer;
}

.tab-content {
  display: grid;
  place-content: center;
}

table {
  border: none;
  table-layout: fixed;
  width: 32rem;
}

th, td {
  border: none;
  padding: 0.4rem;
  text-align: center;
  width: 33.3%;
}

td.la {
  text-align: left;
  padding-left: 1rem;
}

.active-tab {
  flex: 1;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  background-color: #84B7C9;
  color: #fff;
  cursor: default;
}

@media screen and (max-device-width: 768px) {
    .App {
      min-width: none;
    }
    .widestText {
      padding-right: 1rem;
      padding-left: 1rem;
      max-width: 85fvw;
    }
    .loginButton {
      font-size: 1.8rem;
      margin-top: 0.9rem;
      padding-left: 1.4rem;
      padding-right: 1.25rem;
      padding-top: 0.7rem;
      padding-bottom: 0.9rem;
      border-radius: 0.4rem;
    }
    .tabs {
      font-size: 0.8rem;
    }
    .tab {
      padding: 0.25rem;
    }
    .active-tab {
      padding: 0.25rem;
    }
    th, td {
      font-size: 0.8rem;
    }
    h2.no-authorization {
      font-size: 1.2rem;
    }
    button {
      border-radius: 0.6rem; 
      padding: 0.2rem;
    }
    img.exitImg {
      height: 2.6rem;
      width: 2.6rem;
    }
    button.exit {
      height: 2.6rem;
      width: 2.6rem;
    }
  }
  
  @media screen and (min-device-width: 769px) {
    .App {
      min-width: 34rem;
    }
    .widestText {
      padding-right: 0px;
      padding-left: 0px;
      max-width: 32rem;
    }
    .loginButton {
      font-size: 2rem;
      margin-top: 1rem;
      padding-left: 0.8rem;
      padding-right: 0.7rem;
      padding-top: 0.4rem;
      padding-bottom: 0.5rem;
      border-radius: 0.15rem;
    }
    .tabs {
      font-size: 0.85rem;
      display: flex;
      width: 30rem;
    }
    th, td {
      font-size: 1rem;
    }
    h2.no-authorization {
      font-size: 1.2rem;
    }
    button {
      border-radius: 0.4rem;
    }
    img.exitImg {
      height: 2rem;
      width: 2rem;
    }
    button.exit {
      height: 2rem;
      width: 2rem;
    }
  }