html {
  font-size: 17px;
}

@media (min-resolution: 2dppx) {
  html {
    font-size: 20px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Arial, Helvetica, sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}