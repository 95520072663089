.App {
  text-align: center;
}

.removeHeaderFooter {
  display: none;
}

#main_logo_container {
  display: flex;
  justify-content: center;
}

#main_logo {
  width: 15rem;
  height: 15rem;
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
}

footer {
  margin-top: 3rem;
  text-align: center;
  display: grid;
  place-content: center;
}

.minifyFooter {
  font-size: 0.7rem;
}

@media screen and (max-device-width: 768px) {
  .App {
    min-width: none;
  }
  .widestText {
    padding-right: 1rem;
    padding-left: 1rem;
    max-width: 85fvw;
  }
}

@media screen and (min-device-width: 769px) {
  .App {
    min-width: 34rem;
  }
  .widestText {
    padding-right: 0px;
    padding-left: 0px;
    max-width: 32rem;
  }
}