h2 {
  text-align: center;
  font-size: 1.4rem;
}

.loginButton { 
  background-color: #9CD9EE;
  text-decoration: none;
  color: black;
}

button {
  background-color: #9CD9EE;
  cursor: pointer;
  border-top: 0.1rem solid rgba(255, 255, 255, 1);
  border-bottom: 0.1rem solid rgba(108, 108, 108, 1);
  border-right: 0.1rem solid rgba(108, 108, 108, 1);
  border-left: 0.1rem solid rgba(255, 255, 255, 1);
}
button:hover {
  background-color: #84B7C9;
}
button:active {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 1);
  border-top: 0.1rem solid rgba(108, 108, 108, 1);
  border-left: 0.1rem solid rgba(108, 108, 108, 1);
  border-right: 0.1rem solid rgba(255, 255, 255, 1);
}

button.exit {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 0px;
  padding: 0px;
  border: 0px;
  margin-left: 0.4rem;
}

img.exitImg {
  vertical-align: middle;
}

.no-authorization {
  color: #D2042D;
}

.greeting {
  color: #84B7C9;
  font-weight: 600;
  margin: 0px;
}

@media screen and (max-device-width: 768px) {
    .App {
      min-width: none;
    }
    .widestText {
      padding-right: 1rem;
      padding-left: 1rem;
      max-width: 85fvw;
    }
    .loginButton {
      font-size: 1.8rem;
      margin-top: 0.9rem;
      padding-left: 1.4rem;
      padding-right: 1.25rem;
      padding-top: 0.7rem;
      padding-bottom: 0.9rem;
      border-radius: 0.4rem;
    }
    .greeting {
      font-size: 1.7rem;
    }
    h2.no-authorization {
      font-size: 1.2rem;
    }
    button {
      border-radius: 0.6rem; 
      padding: 0.2rem;
    }
  }
  
  @media screen and (min-device-width: 769px) {
    .App {
      min-width: 34rem;
    }
    .widestText {
      padding-right: 0px;
      padding-left: 0px;
      max-width: 32rem;
    }
    .loginButton {
      font-size: 2rem;
      margin-top: 1rem;
      padding-left: 0.8rem;
      padding-right: 0.7rem;
      padding-top: 0.4rem;
      padding-bottom: 0.5rem;
      border-radius: 0.15rem;
    }
    .greeting {
      font-size: 2rem;
    }
    h2.no-authorization {
      font-size: 1.2rem;
    }
    button {
      border-radius: 0.4rem;
    }
  }